import styled from "styled-components";
import { device } from "../../components/utils/devices";

import head_pic from "../../imgs/header_pic.jpg";
import globalColors from "../../components/utils/global-colors";

const { black, white } = globalColors;

const StyledHeader = styled.div`
    background-color: ${black};
    background-image: url(${head_pic});
    background-position-x: center;
    background-position-y: 0;
    background-repeat: no-repeat;
    background-size: cover;

    .box {
      align-items: center;
      height: calc(100vh - 132px);
      justify-content: center;
      margin: 0 auto;
      padding: 52px 0 80px;
      width: 1440px;

      h1 {
        color: ${white};
        font-weight: 500;
        margin: 280px 0 60px;
        text-align: center;
      } 

      @media (min-width: 1680px) {
        h1 {
          font-size: 80px !important;
        }
      }
      .main-button {
        background: linear-gradient(92.89deg, #727272 -56.44%, #FFFFFF 26.16%, #FFFFFF 77.35%, #727272 174.34%);
        border: none;
        border-radius: 1px;
        letter-spacing: 0.08em;
        text-transform: none;
      }
    }
    
  @media ${device.laptopL} {
    .box {
      width: calc(100vw - 160px);
    }
  }

  @media ${device.laptop} {
    .box {
      min-height: 680px;
    }
  }

  @media ${device.tablet} {
    background-position-x: 60%;
    background-size: 1440px auto;
    .box {
      height: calc(100vh - 128px);
      padding: 48px 0 80px;
       h1 {
        font-size: 56px;
       }
    }
  }
  @media ${device.mobile} {
    background-position-x: 85%;
    background-size: 1101px auto;
    .box {
      min-height: 480px;
      width: calc(100% - 40px);
       h1 {
        font-size: 30px;
        margin: 0 0 60px;
       }

       .main-button {
        height: 64px;
        width: calc(100vw - 40px);
       }
    }
  }
`;

export default StyledHeader;
