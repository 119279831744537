import React, { useState, useEffect } from "react";
import Link from "../../components/link";
import { StyledMenu } from "./styled-menu";
import Box from "../../components/box";
import hamb from "../../imgs/ham.svg";
import cross from "../../imgs/cross.png";

const Menu = ({ t, template, ...rest }) => {
  const [windowCheck, setWindowCheck] = useState("undefined");
  const [navbar, setNavbar] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    if (typeof window !== windowCheck) {
      setWindowCheck(window.innerWidth <= 1190);
    }
  }, [windowCheck]);
  const hg = window.innerHeight - 52;

  const changeBackground = () => {
    if (window.scrollY >= hg) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    changeBackground()
    window.addEventListener("scroll", changeBackground)
  })

  return (
    <StyledMenu
      className={navbar ? "navbar active" : "navbar"}
    >
      <Box className="menu">
        <img src={showMenu ? cross : hamb} alt="menu" className="hamburger" onClick={toggleMenu} />
        <a className="logo" href="/">
          <div className="site-logo"></div>
        </a>
        <nav>
          <ul className="header-menu" id={showMenu ? "on" : "off"}>
            <li className="menu-item" onClick={toggleMenu}>
              <a href="#about">Обо мне</a>
            </li>
            <li className="menu-item" onClick={toggleMenu}>
              <a href="#activity">Мероприятия</a>
            </li>
            <li className="menu-item" onClick={toggleMenu}>
              <a href="#samples">Эксклюзив</a>
            </li>
            <li className="menu-item" onClick={toggleMenu}>
              <a href="#faq">FAQ</a>
            </li>
            <li className="menu-item" onClick={toggleMenu}>
              <a href="#review">отзывы</a>
            </li>
          </ul>
        </nav>
        <Link className="phone" href="tel:+79050122888"><span>+7 (905) 012-28-88</span></Link>
      </Box>
    </StyledMenu>
  );
};

export default Menu;
