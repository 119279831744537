import React from "react";
import Box from "../../components/box";
import StyledFAQ from "./styled-faq";
import Heading from "../../components/heading";
import { AccordionItem } from "../../components/accordion";

const FAQ = ({ ...rest }) => {
  return (
    <StyledFAQ id="faq">
      <Box>
        <Heading level={2}>Часто задаваемые вопросы</Heading>
        <AccordionItem heading="Из чего состоят духи, которые ты создаешь?">
          Парфюм создается из качественных компонентов (душистых веществ) от ведущих парфюмерных лабораторий (Франция, США, Россия и т.д). В качестве основы выступает этиловый спирт.
        </AccordionItem>
        <AccordionItem heading="Есть ли подарочные сертификаты?">
          В продаже есть подарочные сертификаты, на которых я напишу любую услугу, которую вы хотели бы подарить.
        </AccordionItem>
        <AccordionItem heading="Сколько времени занимает создание духов?">
          На создание аромата как лично, так и в формате мастер-класса потребуется 2-2,5 часа.
        </AccordionItem>
        <AccordionItem heading="Можно ли использовать духи сразу после создания?">
          Аромат, который мы создали, должен настояться минимум 2 недели, а в идеале 3 месяца. Так как компоненты должны смешаться между собой, чтобы проявить себя во всей красе и радовать своей стойкостью и насыщенностью.
        </AccordionItem>
        <AccordionItem heading="Что делать если не могу приехать к тебе в студию?">
          Если нет возможности приехать - не беда, я активно практикую дистанционное создание парфюма. Готовые духи мужно заказать доставкой или забрать самовывозом. При дополнительном согласовании, возможно создание аромата или проведение мастер-класса у вас дома или в офисе.
        </AccordionItem>
      </Box>
    </StyledFAQ>
  );
};

export default FAQ;