import React from "react";
import Text from "../../components/text";
import Box from "../../components/box";
import Heading from "../../components/heading";
import StyledAbout from "./styled-about";
import quality from "../../imgs/quality.svg";
import portrait from "../../imgs/portrait.svg";
import exclusive from "../../imgs/exclusive.svg";

const About = ({ ...rest }) => {
  return (
    <StyledAbout id="about">
      <Box className="about-box">
        <Heading level={2}>Обо мне</Heading>
        <Box className="outside">
          <Box className="inside">
            <Text className="about-text">Меня зовут Анна и я сертифицированный парфюмер. Концепция, которую я несу в мир парфюмерии, это уникальность. Я помогаю раскрыть внутренний свет каждого с помощью создания персонального аромата. Встреча со мной это не просто флакон духов по завершению, а нечто большее! И каждый кто хоть раз побывал у меня, подтвердит это.</Text>
            <Text className="about-text">Я очень люблю то, чем занимаюсь. Это дело — моя душа и огромная часть жизни. Приходя ко мне, вы через Кроличью нору попадаете в волшебный мир. Это особая атмосфера любви к себе и прекрасному.</Text>
            <Text className="about-text">Я заметила, у всех, кто первый раз переступает порог моей студии в глазах растерянность и некая робость перед неизвестным… А через 1,5 часа я обнимаю на прощание человека, парящего над землёй, с глазами полными искреннего детского восторга и радости. А после, я светящаяся от счастья, смакую приятное послевкусие от прошедшей встречи.</Text>
            <Text className="about-text">Я жду вас в любое время! Приходите не просто создавать аромат, а вместе творить волшебство.</Text>
          </Box>
          <Box className="inside with_img" />
        </Box>
      </Box>
      <Box className="why-box">
        <Heading level={3}>Почему я?</Heading>
        <Box className="container">
          <Box className="inside-container">
            <div className="inside">
              <div className="head">
                <img src={portrait} alt="pic" width={28} height={28} />
                <Heading level={4}>Ароматный портрет</Heading>
              </div>
              <Text className="why-text">Парфюм создается в непосредственном контакте с клиентом в результате 100% попадания в запрос.</Text>
            </div>
          </Box>
          <Box className="inside-container">
            <div className="inside">
              <div className="head">
                <img src={quality} alt="pic" width={28} height={28} />
                <Heading level={4}>качество</Heading>
              </div>
              <Text className="why-text">Я использую качественные компоненты  ведущих французских, американских и российских лабораторий.</Text>
            </div>
          </Box>
          <Box className="inside-container">
            <div className="inside">
              <div className="head">
                <img src={exclusive} alt="pic" width={28} height={28} />
                <Heading level={4}>Эксклюзив</Heading>
              </div>
              <Text className="why-text">Вы становитесь обладателем уникального аромата, который отражает вашу индивидуальность.</Text>
            </div>
          </Box>
        </Box>
      </Box>
    </StyledAbout>
  );
};

export default About;