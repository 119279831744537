import React from "react";
import Text from "../../components/text";
import Box from "../../components/box";
import Heading from "../../components/heading";
import StyledPerfume from "./styled-perfume";
import bar from "../../imgs/anna.png";

const Perfume = ({ ...rest }) => {
  return (
    <StyledPerfume>
      <Box>
        <Heading level={2}>Парфюмерный бар</Heading>
        <div className="img" style={{backgroundImage: `url(${bar})`}}></div>
        <Text className="text">Парфюмерный бар — это стильное развлечение, новинка на свадьбах и корпоративных мероприиятиях, до недавнего времени доступная лишь избранным.</Text>
        <br />
        <Text className="text">Как проходит:</Text>
        <ul>
          <li className="text">Парфюмер встречает гостей в велком зоне и увлекает в волшебный мир ароматов, рассказывая гостю историю о нем самом, его характере и настроении, основываясь на его выборе запахов. А потом создаёт парфюм во флакон, подобранный в стилистике мероприятия.</li>
        </ul>
        <br />
        <Text className="text">Стоимость:</Text>
        <ul>
          <li className="text">До 30 человек — 30 000 ₽</li>
          <li className="text">До 50 человек — 50 000 ₽</li>
        </ul>
        <br />
        <Text className="text">В результате вы получите:</Text>
        <ul>
          <li className="text">Флакон, подобранный под стилистику мероприятия</li>
          <li className="text">Разработка дизайна + изготовление наклейки на флакон</li>
          <li className="text">Изготовление персонального аромата</li>
        </ul>
        <Heading level={3}>Парфюмерный интерактив</Heading>
        <Text className="text">Интерактив парфюмера с гостями во время основной части, в процессе которого гости совместно со специалистом создают аромат торжества или бренда (для корпоративных мероприиятий компании).</Text>
        <br />
        <Text className="text">Как проходит:</Text>
        <ul>
          <li className="text">Из гостей приглашается несколько добровольцев, которые отвечая на вопросы парфюмера и выбирая из предложенных нот (запахов) создают аромат. Увлекательная, ароматная и 100% запоминающаяся активность.</li>
        </ul>
        <br />
        <Text className="text">Стоимость:</Text>
        <ul>
          <li className="text">15 000 ₽</li>
        </ul>
        <br />
        <Text className="text">В результате вы получите:</Text>
        <ul>
          <li className="text">2 флакона: 10 и 20 мл или 15 флаконов по 2 мл, которые можно подарить всем участникам.</li>
        </ul>
      </Box>
    </StyledPerfume>
  );
};

export default Perfume;