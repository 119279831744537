const car_items = [
    {
        name: "Наташа",
        img_path: "images/nata.png",
        description: "Анечка! Спасибо тебе огромное за то, что учла все мои пожелания при создании аромата! Оригинальный, чарующий, звучание дорогое, стойкий! А шлейф... все окружающие в восторге! Это лучший мой подарок самой себе на день рождения!!! 😍"
    },
    {
        name: "Ирина",
        img_path: "images/ira.png",
        description: "Анечка, спасибо тебе большое за духи! 😍 Во-первых, сам процесс создания очень увлекательный и интересный, очень здорово, что можно отследить весь процесс и по его ходу что-то менять/добавлять 😍 Во-вторых, они получились именно такими, какие я и хотела! Восточный сказочный аромат с древесным оттенком, в меру сладкий, как я люблю 🤩🤩🤩 Мне очень нравится ❤ И я уже знаю, что хочу заказать в следующий раз "
    },
    {
        name: "Лера",
        img_path: "images/lera.png",
        description: "Духи просто на миллион из десяти! Уже половины нет. Всем, кто будет читать этот отзыв, говорю: заказывайте сейчас, скоро работы Ани будут стоить в разы дороже!"
    },
    {
        name: "Алиса",
        img_path: "images/alice.png",
        description: "В общем я каждые 5 минут нюхаю свои духи после вчерашнего! Мы в восторге! Такое необычное романтичное приключение, а с собой у нас бутылочки с волшебством, которые будут долго возвращать в этот удивительный вечер!"
    },
    {
        name: "Ирина",
        img_path: "images/irina.png",
        description: "Благодарю Анну за незабываемую встречу с ароматами. Вы дали мне возможность побыть в роли создателя. Аромат, который получился во время нашего общения, пришелся по душе не только мне, но и окружающим людям. Он окрыляет, вдохновляет и притягивает людей, вызывая у них моментальную симпатию к моей личности. А вот уже после сеанса ты чувствуешь себя отдохнувшей и счастливой."
    },
    {
        name: "Анжелика",
        img_path: "images/lika.png",
        description: "Анна исполнила мою мечту и создала восхитительный индивидуальный запах в канун приближающегося дня рождения. Признаюсь честно, знаем друг друга давно и выполнить пожелания для прекрасной Анюты было несложно. В этом ей помогли воспоминания детства и старое фото из её семейного альбома. Запах получился необычный, неоднозначный, загадочный и запоминающийся!!! Такой, как я мечтала!!! Друзья, если Вы тоже мечтаете о таком подарке - я рекомендую! Анна Ковалева осуществит ваши желания!"
    },
]

export default car_items;