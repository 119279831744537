import styled from "styled-components";
import { Base } from "../../themes";
import { device } from "../../utils/devices";
import globalColors from "../../utils/global-colors";

const { black, white } = globalColors;

const StyledAccordionItem = styled.div`
color: ${white};
position: relative;
border-top: 1px solid ${white};
padding: 24px 48px 24px 20px;

.accordion {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  outline: none;
  align-items: center;
}

.icon {
  font-size: 64px;
  line-height: 24px;
  font-weight: 300;
  position: absolute;
  left: calc(100% - 40px);
  top: 34px;
  transition: transform 0.2s ease;
}

.content {
  background-color: ${black};
  overflow: hidden;
  transition: max-height 0.2s ease;
}

.text {
  font-size: 24px;
  font-weight: 400;
  line-height: 156%;
  letter-spacing: 0.04em;
  padding: 20px 0 24px;
  text-align: left;
}

.heading {
  color: ${white};
  font-weight: 500;
  outline: none;
}

@media ${device.tablet} {
  .heading {
      font-size: 20px;
  }
  .text {
    font-size: 18px;
  }
}
@media ${device.mobile} {
  .heading {
      font-size: 18px;
  }
  .text {
    font-size: 16px;
  }
}
`;

StyledAccordionItem.defaultProps = { theme: Base };

export default StyledAccordionItem;
