import styled from "styled-components";
import { device } from "../../utils/devices";
import globalColors from "../../utils/global-colors";
const { white } = globalColors;

const StyledSlide = styled.div`
overflow: hidden;
  .name {
    font-size: 24px;
    font-weight: 500;
    line-height: 156%;
    text-align: center;
    letter-spacing: 0.04em;
    padding: 20px 0 24px;
  }

  .desc {
    background-color: ${white};
    cursor: pointer;
    font-weight: 300;
    font-size: 18px;
    line-height: 156%;
    letter-spacing: 0.04em;
    text-align: center;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10; /* start showing ellipsis when 3rd line is reached */
    white-space: pre-wrap;
    text-overflow: fade;
  }

  &.show{
    overflow: visible; 
    .desc {
      overflow: visible; 
      -webkit-line-clamp: unset;
    }
  }

  .image {    
    text-align: center;
    margin: 0 auto;
    width: 180px;
  }
  @media ${device.tablet} {
    .image {
      width: 140px;
    }
    .name {
      font-size: 20px;
      padding: 12px 0 24px;
    }
  }
  @media ${device.mobile} {
    .desc {
      font-size: 14px;
    }
    .image {    
      width: 120px;
    }

    .name {
      font-size: 16px;
      padding: 8px 0 20px;
    }
  }
`;

export default StyledSlide;
