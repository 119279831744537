import styled from "styled-components";
import { device } from "../../components/utils/devices";
import globalColors from "../../components/utils/global-colors";

const { black } = globalColors;

const StyledFooter = styled.div`
    background-color: ${black};

    .footer {
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      padding: 80px 100px;
      width: 1240px;

      .links {
        justify-content: space-evenly;
        gap: 0 32px;
        padding: 32px 0 0;
        &:last-child {
          justify-content: center;
        }
        > a {
          text-decoration: none;
        }
      }

      > div {
        display: block;
        width: 50%;

        > span {
          color: #FFFFFF;
          display: block;
          font-size: 18px;
          line-height: 156%;  
          letter-spacing: 0.02em;
          padding: 32px 0 0;
          text-align: center;
        }
      }
    }
    @media ${device.laptopL} {
      .footer {
        width: calc(100vw - 200px);
        > div {
          width: calc(50% - 50px);
        }
      }
    }
    @media ${device.laptop} {
      .footer {
        > div {
          width: 100%;
          &:last-child {
            display: none;
          }
        }
      }
    }
    @media ${device.tablet} {
      .footer div {
        span {
          font-size: 16px;
        }
      }
    }
    @media ${device.mobile} {
      .footer {
        padding: 40px 20px;
        width: calc(100vw - 40px);
        > div span {
          padding: 28px 0 0;
        }
        .links {
          gap: 24px 32px;
          flex-direction: column;
          padding: 28px 0 0;
          &:last-child {
            flex-direction: row;
          }
        }
      }
    }
`;

export default StyledFooter;
