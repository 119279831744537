import styled from "styled-components";
import globalColors from "../../components/utils/global-colors";
import { device } from "../../components/utils/devices";
import logo from "../../imgs/ak_logo.svg";
import phone_icon from "../../imgs/phone_icon.svg";
import bg_dust from "../../imgs/bg_dust.png";

const { black, white } = globalColors;

const StyledMenu = styled.div`
  align-items: center;
  border: none;
  margin: 0 auto;
  padding: 0 100px;
  box-sizing: border-box;
  height: auto;
  font-size: 12px;
  color: ${white};
  background-color: transparent;
  backdrop-filter: blur(2px);
  position: fixed;
  width: 100%;
  z-index: 100;

  &.active {
    background-color: ${black};
    transition: 0.6s;
  }

  .hamburger {
    display: none;
  }

  .menu {  
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 72px 6fr 155px;
    grid-template-columns: 72px 6fr 155px;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
    margin: 0 auto;
    width: 1240px;

    .phone {
      color: ${white};
      font-size: 18px;
      text-align: center;
      text-decoration: unset;
    }
  
    .site-logo {
      background: url(${logo}) no-repeat 50% 0;
      background-size: 94px auto;
      margin: 4px 0;
      position: relative;
      height: 44px;
      width: 72px;
    }

    .header-menu {
      display: flex;
      justify-content: space-between;
      gap: 0 48px;
      margin: 0 auto;
      padding: 0;
      width: 640px;
      .menu-item {
        list-style-type: none;
        text-transform: uppercase;
        > a {
          color: ${white};
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0.02em;
          text-decoration: none;
  
          &.active {
            border-bottom: 1px solid ${white};
          }
        }
      }
    }
  }

  @media ${device.laptopL} {
    .menu {
      grid-template-columns: 72px 4fr 155px;
      width: calc(100vw - 200px);
    }
  }

  @media ${device.laptopM} {
    padding: 0 1vw;
    .menu {
      grid-template-columns: 72px 4fr 40px;
      .phone {
        background-image: url(${phone_icon});
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        height: 40px;
        > span {
          display: none;
        }
      }
    }
  }
  @media ${device.laptop} {
    .hamburger {
      cursor: pointer;
      display: block;
      z-index: 112;
    }

    .logo {
      margin: 0 auto;
    }
    .menu {
      grid-template-columns: 40px 4fr 0 40px;
      .header-menu {
        display: none;
      }
      #on.header-menu , #off.header-menu  {
        background-color: ${white};
        background-image: url(${bg_dust});
        background-size: cover;
        display: block;
        position: absolute;
        left: 0;
        padding: 40px 0 8px;
        top: 0;
        transition: all .1s;
        width: 100%;
        z-index: 111;
      }
      #on.header-menu  .menu-item, #off.header-menu  .menu-item {
        font-size: 20px;
        text-align: center;
        padding: 0 0 32px;
        > a {
          color: ${black};
          font-size: 20px;
        }
      }
      #on.header-menu  {
        animation: slide-in .2s ease-in-out;
      }
      @keyframes slide-in {
        0% {
          transform: translateX(1100%);
        }
        100% {
          transform: translateX(0);
        }
      }
      #off.header-menu  {
        left: -100%;
        animation: slide-out .2s ease-in-out;
      }
      @keyframes slide-out {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-100%);
        }
      }
      .phone {
        font-size: 16px;
      }
      .site-logo {
        background-size: 91px auto;
        margin: 4px 0;
        height: 40px;
        width: 63px;
      }
    }
  }

  @media ${device.tablet} {
    padding: 0;
    .menu {
      width: calc(100vw - 160px);
    }
  }

  @media ${device.mobile} {
    .hamburger {
      width: 32px;
    }
    .menu {
      width: calc(100vw - 48px);
    }

    .phone {
      height: 32px;
      width: 32px;
    }
  }
`;

export { StyledMenu };
