import React, { useState } from "react";
import StyledSlide from "./styled-slide";
import Text from "../../text";

const Slide = ({ arrayItems, ...rest }) => {
  const [active, setActive] = useState(false);
  return (
    <StyledSlide {...rest} className={active && "show"} active={active} onClick={() => setActive(!active)}>
        <img className="image" alt="user_pic" src={ arrayItems.img_path }></img>
        <Text className="name">{arrayItems.name}</Text>
        <Text className="desc">{arrayItems.description}</Text>
    </StyledSlide>
  );
};


export default Slide;