import styled from "styled-components";
import { device } from "../../components/utils/devices";
import bg_dust from "../../imgs/bg_dust.png";
import globalColors from "../../components/utils/global-colors";

const { white, black } = globalColors;

const StyledActivity = styled.div`
    background-color: ${white};
    background-image: url(${bg_dust});
    background-size: 1440px auto;

    .act {
      display: block;
      margin: 0 auto;
      padding: 80px 100px;
      width: 1240px;

      h2 {
        font-weight: 600;
        text-align: center;
      }

      .collections-box {
        display: block;
        padding: 60px 0 0;
        width: 100%;

        .about {
          background-color: ${white};
          display: block;
          font-weight: 300;
          font-size: 18px;
          line-height: 156%;
          letter-spacing: 0.02em;
          padding: 0 0 8px;
        }

        h3 {
          font-size: 32px;
          font-weight: 500;
          padding: 0 0 40px;
          margin: 0 auto;
          text-align: center;
        }

        h4 {
          font-weight: 400;
          margin: 0 auto;
          padding: 24px 0 0px;
          text-align: center;
        }

        .collection-box {
          align-items: flex-start;
          justify-content: center;
          display: flex;
          gap: 0 32px;
          overflow-x: auto;
          padding: 24px 130px 0px;
          > div {
            background-color: ${white};
            text-align: center;
            width: 100%;
            max-width: 302px;

            &.vid_trigger {
              cursor: pointer;
              position: relative;
              &:after {
                border-top: 6px solid transparent;
                border-left: 8px solid #fff;
                border-bottom: 6px solid transparent;
                color: ${black};
                content: "";
                left: calc(50% - 1px);
                position: absolute;
                top: calc(50% - 46px);
              }
              &:before {
                background: ${black};
                border-radius: 4px;
                content: "";
                height: 28px;
                left: calc(50% - 12px);
                opacity: 0.8;
                position: absolute;
                top: calc(50% - 54px);
                width: 28px;
              }
            }

            .pic {
              width: 300px;
              height: 400px;
              object-fit: cover;
            }

            .text {
              display: block;
              font-size: 20px;
              font-weight: 400;
              line-height: 156%;
              letter-spacing: 0.04em;
              padding: 20px 0 16px;
              text-align: center;
            }

            > span {
              display: block;
              font-weight: 300;
              font-size: 18px;
              text-align: center;
            }
          }
        }
      }
    }
    @media ${device.laptopL} {
      .act {
        width: calc(100vw - 200px);
        .collections-box {
          h3 {
            width: calc(100vw - 200px);
          }
        }
      }
    }
    @media (max-width: 1439px) {
      .act .collections-box .collection-box {
        padding: 24px 0px 0px;
      }
    }
    @media ${device.laptopM} { 
      .act .collections-box .collection-box > div {
        .pic {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
    }
    @media ${device.tablet} {
      .act {
        padding: 80px;
        width: calc(100vw - 160px);

        h2 {
          font-size: 48px;
        }
        .collections-box { 
          padding: 40px 0 0;

          h3 {
            padding: 0 0 32px;
          }
          h4 {
            padding: 20px 0 4px;
            width: 70%;
          }

          .collection-box {
          gap: 0 24px;
          padding: 20px 0px 10px;
          > div {
            > span {
              font-size: 16px;
            }

            .text {
              font-size: 18px;
              letter-spacing: 0.02em;
            }
          }
          .pic {
            width: 259px;
            height: 259px;
            object-fit: cover;
          }
        }
        }
      }
    }
    @media ${device.mobile} {
      .act {
        margin: 0;
        padding: 40px 0px 40px 20px;
        width: auto;
        h2 {
          font-size: 32px;
          width: calc(100% - 40px);
        }
        .collections-box {
          padding: 32px 20px 0 0;
          width: calc(100% - 20px);
          &:last-child {
            padding: 32px 0 0 0;
            width: auto;
            h3 {
              margin: 0;
              width: calc(100% - 60px);
            }
            .about {
              margin: 0;
              width: calc(100% - 20px);
            }

            h4 {
              margin: 0;
              width: calc(100% - 60px);
            }
          }

          h4 {
            font-size: 18px;
            padding: 16px 0 4px;
            width: 100%;
          }

          .about {
            font-size: 16px;
          }
          .collection-box {
            display: block;
            padding: 16px 0px 0px;

            &.with_video {
              display: flex;
              justify-content: initial;
              gap: 0 20px;
              padding: 16px 20px 0px 0;
              > div {
                
                .pic {
                  width: 180px;
                }
              }
            }
            > div {
              margin: 0 auto;
              padding: 0 0 16px;

              &:last-child {
                padding: 0;
              }
              .pic {
                height: 280px;
                width: 280px;
            }
            .text {
              font-size: 16px;
              padding: 12px 0 8px;
              }
            }
          }
          h3 {
            font-size: 24px;
            padding: 0 20px 28px;
            margin: 0 auto;
            text-align: center;
            width: calc(100% - 40px);
          }
        }
      }
    }
`;

export default StyledActivity;