import React from "react";
import Box from "../../components/box";
import StyledFooter from "./styled-footer";
import logo from "../../imgs/ak_logo.svg";
import Text from "../../components/text";
import Link from "../../components/link";
import tg from "../../imgs/tg.svg";
import vk from "../../imgs/vk.svg";
import inst from "../../imgs/in.svg";
import { Map, Placemark } from "react-yandex-maps";

const Footer = ({ ...rest }) => {
  const [zoom, setZoom] = React.useState(16);

  const mapState = React.useMemo(
    () => ({ center: [56.327231, 43.994623], controls: ['zoomControl', 'fullscreenControl'], zoom }),
    [zoom]
  );

  return (
    <StyledFooter>
      <Box className="footer">
        <div>
          <img src={logo} alt="logo" />
          <Box className="links">
            <Link href="tel:+79050122888">+7 (905) 012-28-88</Link>
            <Link href="mailto:anka1992@inbox.ru">anka1992@inbox.ru</Link>
          </Box>
          <Text fontSize="16px">г. Нижний Новгород, ул. Почаинская, д. 17М</Text>
          <Box className="links">
            <Link href="https://vk.com/privetannet888"><img src={vk} alt="vk.com" /></Link>
            <Link href="https://t.me/privet_annet888"><img src={tg} alt="telegram" /></Link>
            <Link href="https://instagram.com/privet.annet"><img src={inst} alt="instagram.com" /></Link>
          </Box>
        </div>
        <div>
          <Map width={"100%"} height={365} state={mapState} modules={['control.ZoomControl', 'control.FullscreenControl']}><Placemark defaultGeometry={[56.327231, 43.994623]} /></Map>
        </div>
      </Box>
    </StyledFooter>
  );
};

export default Footer;