import React from "react";
import PropTypes from "prop-types";
import StyledButton from "./styled-button";

const Button = ({
  label,
  href,
  ...rest
}) => {
  return (
    <StyledButton
      {...rest}
    >
      <a href={href}>
        {label}
      </a>
      
    </StyledButton>
  );
};

Button.propTypes = {
  /** Button text */
  label: PropTypes.string,
  /** Accepts class */
  className: PropTypes.string,
  /** Accepts id */
  id: PropTypes.string,
  /** Accepts CSS style */
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Button.defaultProps = {
  label: "",
  typeButton: "primary",
  width: "auto",
  height: "74px",
  icon: null,
  minwidth: "unset",
};

export default Button;
