import React from "react";
import Text from "../../components/text";
import Box from "../../components/box";
import Heading from "../../components/heading";
import StyledSamples from "./styled-samples";
import mint_lime from "../../imgs/mint_lime.png";
import lemon_coffee from "../../imgs/lemon_coffee.png";
import cranberry from "../../imgs/cranberry.png";
import mint_melon from "../../imgs/mint_melon.png";
import dark_side from "../../imgs/dark_side.png";
import pure from "../../imgs/pure.png";
import spring from "../../imgs/spring.png";
import harmony from "../../imgs/harmony.png";
import passion from "../../imgs/passion.png";
import hoodie_white from "../../imgs/hoodie_white.png";
import hoodie_blck from "../../imgs/hoodie_blck.png";

const Samples = ({ ...rest }) => {
  return (
    <StyledSamples id="samples">
      <Box className="smpls">
        <Heading level={2}>Эксклюзив</Heading>
        <Box className="collections-box">
          <Heading level={3}>Авто-парфюм</Heading>
          <Box className="collection-box">
            <div>
              <img className="pic" src={cranberry} alt="pic" />
              <Heading level={4}>Клюква и можжевельник</Heading>
              <Text>Верхняя нота: пихта</Text>
              <Text>Средняя нота: клюква</Text>
              <Text>Ноты базы: можжевельник</Text>
              <Text>Цена: <span style={{fontWeight: 400, fontSize: 20}}>800 ₽</span></Text>
            </div>
            <div>
              <img className="pic" src={mint_melon} alt="pic" />
              <Heading level={4}>Дынный сорбет</Heading>
              <Text>Верхняя нота: мята</Text>
              <Text>Средняя нота: хлопок</Text>
              <Text>Ноты базы: дыня</Text>
              <Text>Цена: <span style={{fontWeight: 400, fontSize: 20}}>800 ₽</span></Text>
            </div>
            <div>
              <img className="pic" src={lemon_coffee} alt="pic" />
              <Heading level={4}>Карамельно-лимонный раф</Heading>
              <Text>Верхняя нота: лимон</Text>
              <Text>Средняя нота: горячее молоко</Text>
              <Text>Ноты базы: кофе, соленая карамель</Text>
              <Text>Цена: <span style={{fontWeight: 400, fontSize: 20}}>800 ₽</span></Text>
            </div>
            <div>
              <img className="pic" src={mint_lime} alt="pic" />
              <Heading level={4}>Бергамот</Heading>
              <Text>Верхняя нота: зеленый чай</Text>
              <Text>Средняя нота: морская соль</Text>
              <Text>Ноты базы: бергамот</Text>
              <Text>Цена: <span style={{fontWeight: 400, fontSize: 20}}>800 ₽</span></Text>
            </div>
          </Box>
        </Box>
        <Box className="collections-box diff">
          <Heading level={3}>диффузоры</Heading>
          <Box className="collection-box">
            <div>
              <img className="pic" src={pure} alt="pic" />
              <Heading level={4}>Pure</Heading>
              <Text>Верхняя нота: белый мускус</Text>
              <Text>Средняя нота: хлопок</Text>
              <Text>Ноты базы: морская соль</Text>
              <Text>Цена: <span style={{fontWeight: 400, fontSize: 20}}>1 500 ₽</span></Text>
            </div>
            <div>
              <img className="pic" src={passion} alt="pic" />
              <Heading level={4}>Passion</Heading>
              <Text>Верхняя нота: амбер</Text>
              <Text>Средняя нота: роза</Text>
              <Text>Ноты базы: кожа, дерево, ваниль</Text>
              <Text>Цена: <span style={{fontWeight: 400, fontSize: 20}}>1 500 ₽</span></Text>
            </div>
            <div>
              <img className="pic" src={dark_side} alt="pic" />
              <Heading level={4}>Dark side</Heading>
              <Text>Верхняя нота: конопля</Text>
              <Text>Средняя нота: пало санто</Text>
              <Text>Ноты базы: шафран</Text>
              <Text>Цена: <span style={{fontWeight: 400, fontSize: 20}}>1 500 ₽</span></Text>
            </div>
            <div>
              <img className="pic" src={harmony} alt="pic" />
              <Heading level={4}>Harmony</Heading>
              <Text>Верхняя нота: лаванда</Text>
              <Text>Ноты базы: ваниль</Text>
              <Text>Цена: <span style={{fontWeight: 400, fontSize: 20}}>1 500 ₽</span></Text>
            </div>
            <div>
              <img className="pic" src={spring} alt="pic" />
              <Heading level={4}>Spring</Heading>
              <Text>Верхняя нота: груша</Text>
              <Text>Средняя нота: лёд</Text>
              <Text>Ноты базы: нероли, жасмин</Text>
              <Text>Цена: <span style={{fontWeight: 400, fontSize: 20}}>1 500 ₽</span></Text>
            </div>
          </Box>
        </Box>
        <Box className="collections-box">
          <Heading level={3}>Худи</Heading>
          <Box className="collection-box hoodie">
            <div>
              <img className="pic" src={hoodie_white} alt="pic" />
              <Heading level={4}>Пахну как любовь</Heading>
              <Text>Цвет: белый</Text>
              <Text>Подарок: «Аромат Любви», 2 мл</Text>
              <Text>Цена: <span style={{fontWeight: 400, fontSize: 20}}>6 000 ₽</span></Text>
            </div>
            <div>
              <img className="pic" src={hoodie_blck} alt="pic" />
              <Heading level={4}>Пахну как любовь</Heading>
              <Text>Цвет: черный</Text>
              <Text>Подарок: «Аромат Любви», 2 мл</Text>
              <Text>Цена: <span style={{fontWeight: 400, fontSize: 20}}>6 000 ₽</span></Text>
            </div>
          </Box>
        </Box>
      </Box>
    </StyledSamples>
  );
};

export default Samples;