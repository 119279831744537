import React, { useState } from "react";
import Text from "../../components/text";
import Box from "../../components/box";
import Heading from "../../components/heading";
import StyledActivity from "./styled-activity";
import ml_10 from "../../imgs/ml_10.png";
import ml_20 from "../../imgs/ml_20.png";
import ml_50 from "../../imgs/ml_50.png";
import prev_1 from "../../imgs/prev_1.svg";
import prev_2 from "../../imgs/prev_2.svg";
import prev_3 from "../../imgs/prev_3.svg";
import Modal from "../../components/modal/modal";
import vid_1 from "../../vids/vid_1.mp4";
import vid_2 from "../../vids/vid_2.mp4";
import vid_3 from "../../vids/vid_3.mp4";

const Activity = ({ ...rest }) => {
  const [openModal, setOpenModal] = useState(false);
  const [vidData, setVidData] = useState(null);
  return (
    <StyledActivity id="activity">
      <Box className="act">
        <Heading level={2}>Мероприятия</Heading>
        <Box className="collections-box">
          <Heading level={3}>Персональный аромат</Heading>
          <Text className="about">Создание персонального аромата — это основа моего дела. Вы можете посетить мою мастерскую или связаться со мной удалённо. В ходе сессии мы определяем ароматы которые вам нравятся, смешиваем в желаемой пропорции, опираясь на результат после добавления каждого компонента. В результате вы получаете готовую парфюмерную композицию, которую мы при желании сможем повторить в любом объёме.</Text>
          <Box className="collection-box">
            <div>
              <img className="pic" src={ml_10} alt="pic" />
              <Text className="text">Флакон 10 мл</Text>
              <Text>Цена: <span style={{fontWeight: 400, fontSize: 20}}>5 000 ₽</span></Text>
            </div>
            <div>
              <img className="pic" src={ml_20} alt="pic" />
              <Text className="text">Флакон 20 мл</Text>
              <Text>Цена: <span style={{fontWeight: 400, fontSize: 20}}>8 000 ₽</span></Text>
            </div>
            <div>
              <img className="pic" src={ml_50} alt="pic" />
              <Text className="text">Любой объём</Text>
              <Text>Цена: <span style={{fontWeight: 400, fontSize: 20}}>по запросу</span></Text>
            </div>
          </Box>
        </Box>
        <Box className="collections-box">
          <Heading level={3}>Мастер-классы</Heading>
          <Text className="about">Провожу мастер-класс для влюблённых пар, компании друзей или коллег, подарю увлекательный опыт и непередаваемые эмоции, которые будут возвращаться каждый раз при нанесении аромата! Каждый гость создаст свою собственную парфюмерную композицию и унесёт её с собой во флаконе 10 мл (больший объём обсуждается отдельно).</Text>
          <Text className="about">Количество участников в моей студии от 2 до 4 человек. Стоимость 4 000 ₽ с каждого участника.</Text>
          <Text className="about">Так же провожу выездные мастер-классы, на вашей территории, количество участников и программа обсуждается отдельно и адаптируется под ваш запрос!</Text>
          <Heading level={4}>Самые важные этапы каждого мастер-класса</Heading>
          <Box className="collection-box with_video">
            <div className="vid_trigger" onClick={() => {setOpenModal(true); setVidData(vid_1)}}>
              <img className="pic" src={prev_1} alt="pic" />
              <Text className="text">Парфюмерная лекция</Text>
            </div>
            <div className="vid_trigger" onClick={() => {setOpenModal(true); setVidData(vid_2)}}>
              <img className="pic" src={prev_2} alt="pic" />
              <Text className="text">Подбор компонентов</Text>
            </div>
            <div className="vid_trigger" onClick={() => {setOpenModal(true); setVidData(vid_3)}}>
              <img className="pic" src={prev_3} alt="pic" />
              <Text className="text">Процесс создания</Text>
            </div>
          </Box>
        </Box>
      </Box>
      { openModal && <Modal onClose={setOpenModal} vidSource={vidData} /> }
    </StyledActivity>
  );
};

export default Activity;