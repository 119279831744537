import React from "react";
import Box from "../../components/box";
import Button from "../../components/button";
import Heading from "../../components/heading";
import StyledHeader from "./styled-header";

const Header = ({ ...rest }) => {
  return (
    <StyledHeader>
      <Box>
        <div className="center-content">
          <Heading level={1}>Исполню твои <br /> парфюмерные мечты</Heading>
          <Button className="main-button" label="Связаться" href="https://t.me/privet_annet888" />
        </div>
      </Box>
    </StyledHeader>
  );
};

export default Header;