import React from "react";
import ReactPlayer from "react-player";
import StyledModal from "./styled-modal.js";
import cross from "../../imgs/cross.png";

const Modal = ({ vidSource, onClose }) => {
  return (
    <StyledModal onClick={() => onClose(false)}>
      <div className="modal" onClick={e => e.stopPropagation()}>
        <div className="modal_header">
          <img src={cross} onClick={() => onClose(false)} alt="close" />
        </div>
        <ReactPlayer
            className='react-player fixed-bottom video'
            url= {vidSource}
            width='100%'
            height='100%'
            controls = {true}
            volume={0}
            muted={true}
            />
      </div>
    </StyledModal>
  );
}

export default Modal;