import styled from "styled-components";
import arrow from "../../imgs/arrow.png";
import { device } from "../../components/utils/devices";

const StyledCarousel = styled.div`
  margin: 0 auto;
  padding: 0px;
  width: 85%;
  .slick-slider {
    margin: 0 auto;
    padding: 0px;

    .slick-track {
      display: flex;
      gap: 24px;
    }
    .slide {
      .carousel-image {
        width: 100%;
      }
    }
    .slick-list {
      margin: 40px 60px;
    }
    .slick-arrow {
      position: relative;
      cursor: pointer;
      font-size: 0;
      height: 12px;
      outline: none;
      display: block;
      width: 26px;
      margin: 0 auto;
    }
    .slick-arrow:before {
      content: "";
      background-image: url(${arrow});
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: contain;
      display: block;
      height: 33px;
      width: 19px;
      margin: 0 auto;
    }
    .slick-prev, .slick-next {
      z-index: 1;
      transform: none !important;
      position: absolute;
      top: 50%;
    }
    .slick-prev::before {
      transform: rotate(180deg);
    }
    .slick-next::before {
      transform: rotate(0deg);
    }
    .slick-dots li.slick-active
    {
        display: inline-block;
    }
    li button {
      background: #ccc;
      border: none;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      color: transparent;
      font-size: 0px;
      height: 8px;
      outline: none;
      padding: 0;
      width: 8px;
      &:before {
        display: none;
      }
  }
  li.slick-active button {
    background: #333333;
  }
  .slick-dots li {
    display: inline-block;
    position: relative;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    width: 20px;
  }
  .slick-dots {
    bottom: -45px;
    display: block;
    list-style: none;
    margin: 0 auto;
    padding: 0;
  }
  }   

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.tablet} {
    .slick-slider {
      .slick-arrow {
        display: none !important;;

        &:before, &:after {
          display: none;
        }
      }
      .slick-list {
        margin: 40px 0px;
      }
    }
  }
  @media ${device.mobile} {
    .slick-slider .slick-track {
      gap: 0px; 
  }
  }
`;

export default StyledCarousel;
