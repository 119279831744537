import styled from "styled-components";
import { device } from "../../components/utils/devices";

const StyledModal = styled.div` 
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(114, 114, 114, 0.7);
    width: 100%;
    height: 100%;
    z-index: 1000;
.modal {
    aspect-ratio: 1 / 1.77;
    border-radius: 20px;
    position: fixed;
    top: calc(50% - 20vw);
    left: calc(50% - 11vw);
    width: 22vw;
    z-index: 100;
}

.modal_header {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    left: 36px;
    > img {
        cursor: pointer;
    }
}

.video {
    top: -36px;
    position: relative;
    border-radius: 20px;
    max-height: 990px;
    > video {
        border-radius: 20px;
    }
}

@media ${device.laptopM} {
    .modal {
        top: calc(50% - 35.5vw);
        left: calc(50% - 20vw);
        width: 40vw;
    }}
@media ${device.laptop} {
.modal {
    top: calc(50% - 30vw);
    left: calc(50% - 17vw);
    width: 35vw;
}}
@media ${device.tablet} {
    .modal {
        top: calc(50% - 44vw);
        left: calc(50% - 25vw);
        width: 50vw;
    }
}
@media ${device.mobile} {
    .modal_header {
        left: 16px;
        top: -19px;
        > img {
            width: 16px;
            height: 16px;
        }
    }
    .modal {
        top: calc(50% - 79vw);
        left: calc(50% - 45vw);
        width: 90vw;
    }
}
`
export default StyledModal;