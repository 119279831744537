import styled from "styled-components";
import { device } from "../../components/utils/devices";
import bg_dust from "../../imgs/bg_dust.png";
import about from "../../imgs/about_pic.svg";
import globalColors from "../../components/utils/global-colors";

const { white } = globalColors;

const StyledAbout = styled.div`
    background-color: ${white};
    background-image: url(${bg_dust});
    background-size: 1440px auto;

    .about-box {
      display: block;
      margin: 0 auto;
      padding: 80px 100px 60px;
      width: 1240px;

      .inside {
        background-color: ${white};
        display: block;
        width: 807px;

        &.with_img {
          background-image: url(${about});
          background-size: 650px auto;
          background-position: 50% -70px;
          height: 456px;
          width: 430px;
        }
      }

      .outside {
        gap: 0 40px;
      }

      .about-text {
        display: block;
        font-size: 18px;
        line-height: 156%;
        letter-spacing: 0.02em;
        padding: 0 0 8px;
        text-align: left;
      }

      h2 {
        font-weight: 600;
        padding: 0 0 60px;
        text-align: center;
      }
    }

    .why-box {
      display: block; 
      margin: 0 auto;
      padding: 0 100px 80px;
      width: 1240px;
      h3 {
        font-weight: 500;
        text-align: center;
      }
      .container {
        gap: 0 20px;
        padding: 40px 0 0;
        align-items: start;
        .inside-container {
          align-items: baseline;
          border-left: 0.5px solid #000000;
          height: max-content;
          width: 33%;
          .inside {
            padding: 0 0 0 20px;
            text-align: left;

            .head {
              display: inline-flex;
              gap: 0 12px;
              justify-content: center;
              align-items: center;

              h4 {
                font-weight: 400;
              }
            }
  
            .why-text {
              background-color: ${white};
              font-size: 18px;
              line-height: 156%;
              letter-spacing: 0.02em;
              padding: 20px 0 0;
            }
          }
        } 
      }
    }

    @media ${device.laptopL} {
      .about-box {
        width: calc(100vw - 200px);

        .inside {
          width: 60vw;
          &.with_img {
            width: 40vw;
          }
        }

        .about-text {
          width: 100%;
        }
      }
      .why-box {
        width: calc(100vw - 200px);
      }
    }
    @media ${device.laptop} {
      .about-box {
        padding: 80px 100px 60px;
        .about-text {
          padding: 0 0 24px;
          width: 100%;
        }

        .inside {
          width: 100%;
        }

        .inside.with_img {
          background-size: cover;
          background-position: 0 15%;
          height: 400px;
          width: 100%;
        }

        .outside {
          flex-direction: column-reverse;
          gap: 40px 0;
        }
      }
      .why-box .container {
        display: block;
        .inside-container {
          height: max-content;
          width: 100%;
          margin-bottom: 40px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    @media ${device.tablet} {
      .about-box {
        padding: 80px 80px 16px;
        width: calc(100vw - 160px);
        h2 {
          font-size: 48px;
          padding: 0 0 40px;
        }
      }
      .why-box {
        padding: 00px 80px 80px;
        width: calc(100vw - 160px);
      }
    }
    @media ${device.mobile} {
      .about-box {
        padding: 40px 20px 8px;
        width: calc(100vw - 40px);
        .about-text {
          font-size: 16px;
        }
        h2 {
          font-size: 32px;
          padding: 0 0 32px;
        }
        .inside:last-child {
          height: 60vw;
        }
        .outside {
          gap: 28px 0;
        }
      }
      .why-box {
        padding: 0px 20px 40px;
        width: calc(100vw - 40px);

        .container {
          padding: 28px 0 0;

          h4 {
            font-size: 18px;
          }
        }

        h3 {
          font-size: 24px;
        }
      }
    }
`;

export default StyledAbout;
