import styled from "styled-components";
import { device } from "../../components/utils/devices";
import globalColors from "../../components/utils/global-colors";

const { black } = globalColors;

const StyledQuote = styled.div`
  background-color: ${black};
    .box {
      justify-content: center;
      display: flex;
      margin: 0 auto;
      padding: 100px 100px;
      width: 800px;

      .quote-text {
        color: #ffffff;
        font-style: italic;
        font-weight: 500;
        font-size: 52px;
        line-height: 1.19em;
        text-align: center;
        text-transform: uppercase;
      }
    }
    @media ${device.laptop} {
      .box {
        width: 70vw;
      }
    }
    @media ${device.tablet} {
      .box {
        padding: 80px;
        width: calc(100vw - 160px);
         .quote-text {
          font-size: 42px;
         }
      }
    }
    @media ${device.mobile} {
      .box {
        padding: 40px 20px;
        width: calc(100vw - 40px);
        .quote-text {
          font-size: 28px;
         }
      }
    }
`;

export default StyledQuote;
