import styled from "styled-components";
import { device } from "../../components/utils/devices";
import bg_dust from "../../imgs/bg_dust.png";
import globalColors from "../../components/utils/global-colors";

const { white } = globalColors;

const StyledSamples = styled.div`
    background-color: ${white};
    background-image: url(${bg_dust});
    background-size: 1440px auto;

    .smpls {
      display: block;
      margin: 0 auto;
      padding: 80px 0px 80px 100px;

      h2 {
        font-weight: 600;
        padding-right: 80px;
        text-align: center;
      }

      .collections-box {
        display: block;
        padding: 60px 0 0;
        width: 100%;

        h3 {
          font-size: 32px;
          font-weight: 500;
          padding: 0 180px 0 100px;
          margin: 0 auto;
          text-align: center;
        }

        .collection-box {
          align-items: flex-start;
          justify-content: center;
          display: flex;
          gap: 0 24px;
          overflow-x: auto;
          padding: 40px 100px 0px 0px;
          > div {
            background-color: ${white};
            text-align: center;
            width: 100%;
            max-width: 302px;

            &:nth-child(2n) {
              padding: 240px 0 0;
            }

            .pic {
              width: 292px;
              height: 400px;
              object-fit: cover;
            }

            h4 {
              font-size: 24px;
              font-weight: 400;
              text-align: center;
              padding: 24px 0 16px;
            }
            > span {
              display: block;
              font-size: 18px;
              line-height: 156%;
              letter-spacing: 0.02em;
              padding: 0 0 8px;
              text-align: center;
            }
          }
        }
      }
    }

    @media (max-width: 1756px) {
      .smpls .collections-box.diff .collection-box {
        justify-content: initial;
      }
    }
    @media ${device.laptopL} {
      .smpls {
        h2 {
          text-align: center;
          padding: 0 80px 0 0;
      }
        .collections-box {
          .collection-box {
            justify-content: initial;

            &.hoodie {
              justify-content: center;
            }
          }
          h3 {
            padding: 0 80px 0 0;
        }
        }
      }
    }
    @media ${device.tablet} {
      .smpls {
        padding: 80px 0px 80px 80px;
        h2 {
          font-size: 48px;
        }
        .collections-box {
          padding: 40px 0px 0px;
          .collection-box {
          padding: 40px 80px 10px 0;
          > div {
            h4 {
              font-size: 20px;
              padding: 20px 0;
            }

            > span {
              font-size: 16px;
            }
            
            &:nth-child(2n) {
              padding: 150px 0 0;
            }
          }
          .pic {
            width: 259px;
            height: 259px;
            object-fit: cover;
          }
        }
        }
      }
    }
    @media ${device.mobile} {
      .smpls {
        padding: 40px 0;
        h2 {
          font-size: 32px;
          padding: 0;
        }
        .collections-box {
          padding: 32px 0px 0px;
          .collection-box {
            display: block;
            padding: 28px 20px 0px;
            > div {
              margin: 0 auto;
              &:nth-child(2n) {
                padding: 0px 0 0;
              }
              &:last-child {
                > span:last-child {
                  padding: 0px 0 0;
                }
              }
              h4 {
                font-size: 18px;
                padding: 12px 0;
              }
               .pic {
              width: 280px;
              height: 280px;
              }
              > span {
                font-size: 14px;
                &:last-child {
                  padding: 0 0 24px;
                }
              }
            }
          }
          h3 {
            font-size: 24px;
            padding: 0 20px;
            margin: 0 auto;
            text-align: center;
            width: calc(100vw - 40px);
          }
        }
      }
    }
`;

export default StyledSamples;