import styled from "styled-components";
import { device } from "../../components/utils/devices";
import globalColors from "../../components/utils/global-colors";

const { black, white } = globalColors;

const StyledPerfume = styled.div`
  background-color: ${black};
    .box {
      display: block;
      margin: 0 auto;
      padding: 80px 100px;
      width: 1240px;

      h2 {
        color: ${white};
        font-weight: 600;
        text-align: center;
      }

      h3 {
        color: ${white};
        font-weight: 500;
        padding: 60px 0 32px;
        text-align: center;
      }

      .img {
        background-position: center 60%;
        background-size: cover;
        height: 572px;
        margin: 60px auto;
        padding: 0;
        text-align: center;
        width: 866px;
      }

      ul {
        margin: 8px 0 0;
      }

      li.text {
        display: list-item;
      }

      .text {
        color: ${white};
        display: block;
        font-weight: 300;
        font-size: 18px;
        line-height: 156%;
        padding: 0 0 8px;
        text-align: left;
      }
    }
    @media ${device.laptopL} {
      .box {
        width: calc(100vw - 200px);
      }
    }
    @media ${device.laptop} {
      .box .img {
        aspect-ratio: 1.8;
        height: auto;
        width: 100%;
      }
    }
    @media ${device.tablet} {
      .box {
        padding: 80px;
        width: calc(100vw - 160px);
        h3 {
          padding: 40px 0 32px;
        }

        h2 {
          font-size: 48px;
          width: 100%;
        }
        .img {
          margin: 40px auto 32px;
        }
      }
    }
    @media ${device.mobile} {
      .box {
        padding: 40px 20px 32px;
        width: calc(100vw - 40px);

        h2 {
          font-size: 32px;
          letter-spacing: 0.04em;
        }

        h3 {
          font-size: 24px;
          padding: 32px 0 28px;
        }

        .img {
          aspect-ratio: 1;
          margin: 32px auto 28px;
          width: 280px;
          object-fit: cover;
        }

        .text {
          font-size: 16px;
        }
      }
    }
`;

export default StyledPerfume;
