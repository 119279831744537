import styled from "styled-components";
import { device } from "../../components/utils/devices";
import globalColors from "../../components/utils/global-colors";

const { black, white } = globalColors;

const StyledFAQ = styled.div`
    background-color: ${black};

    .box {
      display: block;
      margin: 0 auto;
      padding: 80px 100px;
      width: 1240px;

      h2 {
        color: ${white};
        font-weight: 600;
        padding: 0 0 60px;
        text-align: center;
      }
    }

    @media ${device.laptopL} {
      .box {
        width: calc(100vw - 200px);
      }
    }
    @media ${device.tablet} {
      .box {
        padding: 80px;
        width: calc(100vw - 160px);
         h2 {
          font-size: 48px;
          padding: 0 0 40px;
         }
      }
    }
    @media ${device.mobile} {
      .box {
        padding: 40px 20px;
        width: calc(100vw - 40px);
         h2 {
          font-size: 32px;
          padding: 0 0 32px;
         }
      }
    }
`;

export default StyledFAQ;
